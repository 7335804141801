// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en.json";
import fa from "./translations/fa.json";

// دریافت زبان از localStorage یا استفاده از زبان پیش‌فرض
const savedLanguage = localStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fa: { translation: fa },
  },
  lng: savedLanguage, // استفاده از زبان ذخیره‌شده یا زبان پیش‌فرض
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

// تابعی برای تغییر زبان و ذخیره آن در localStorage
export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem("language", lng); // ذخیره زبان انتخابی در localStorage
};

export default i18n;
