import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  useTheme,
  Card,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  Select,
  IconButton,
} from "@mui/material";
import Header from "components/Header";
import MyForms from "scenes/MyForms";
import { useTranslation } from "react-i18next";
import {
  getChoicesApi,
  getSpecificFormApi,
  handleViewForm,
  updateFormApi,
  uploadFormApi,
} from "api/formsApi";
import { uploadFileApi } from "api/filesApi";
import Footer from "homepage/Footer/Footer";

const Formsofx2 = () => {
  const navigate = useNavigate();
  const { formStructId } = useParams();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [filePreview, setFilePreview] = useState(null);
  const [selectedFileFieldId, setSelectedFileFieldId] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [repeatableSections, setRepeatableSections] = useState({});
  const [formData, setFormData] = useState({});
  const [submittedFormData, setSubmittedFormData] = useState(null);
  const [redirectToMyForms, setRedirectToMyForms] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const formForEdit = location.state;
  const token = localStorage.getItem('token');


  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (formForEdit) {
      setSelectedForm(formForEdit);
    }
  }, [formForEdit]);

  const MuiControl = () => {
    const originalConsoleWarn = console.warn;
    const originalConsoleError = console.error;

    console.warn = () => {};
    console.error = () => {};

    return () => {
      console.warn = originalConsoleWarn;
      console.error = originalConsoleError;
    };
  };

  useEffect(() => {
    MuiControl();
    return () => MuiControl();
  }, []);

  useEffect(() => {
    if (formForEdit) getDefaultValues();
  }, [selectedForm]);

 

  useEffect(() => {
    const fetchFormStructure = async (lng) => {
      try {
        const response = await getSpecificFormApi(formStructId, lng, token);
        setSelectedForm(response);
      } catch (error) {
        if (error.detail === 'Invalid token.') {
          setError("Session expired. Please log in again.");
          navigate("/login");
        } else {
          setError(error.detail || "An error occurred");
        }
      }
    };
    fetchFormStructure(i18n.language);
  }, [formStructId, i18n.language, token, navigate]);


  {/*
  useEffect(() => {
    const fetchFormStructureForUpdate = async (lng) => {
      try {
        const response = await handleViewForm(formStructId, lng, token);
        setSelectedForm(response);
      } catch (error) {
        if (error.detail === 'Invalid token.') {
          setError("Session expired. Please log in again.");
          navigate("/login");
        } else {
          setError(error.detail || "An error occurred");
        }
      }
    };
    fetchFormStructureForUpdate(i18n.language);
  }, [formStructId, i18n.language, token, navigate]);

*/}

  const handleSubmit = async () => {
    try {
      const mappedDatas = selectedForm.data.map((item) => {
        let updatedFields = item.fields.map((field) => ({
          ...field,
          value: formData[item.section_name]
            ? [formData[item.section_name][field.field_name]]
            : [],
        }));
        return { ...item, fields: updatedFields };
      });

      let response;
      if (formForEdit) {
        response = await updateFormApi(
          {
            form_struct_id: selectedForm.form_struct_id,
            form_struct_group: selectedForm.form_struct_group,
            title: selectedForm.title,
            logo_url: selectedForm.logo_url,
            form_description: selectedForm.form_description,
            language: selectedForm.language,
            pdf_url: selectedForm.pdf_url,
            uploader_username: selectedForm.uploader_username,
            form_id: formForEdit.form_id,
            finalized: selectedForm.finalized,
            reviewers: selectedForm.reviewers,
            approved_by_all_reviewers: false,
            file_urls: [],
            upload_date: selectedForm.upload_date,
            last_modified_date: selectedForm.last_modified_date,
            data: mappedDatas,
          },
          token
        );
      } else {
        response = await uploadFormApi(
          {
            form_struct_id: selectedForm.form_struct_id,
            form_struct_group: selectedForm.form_struct_group,
            title: selectedForm.title,
            logo_url: selectedForm.logo_url,
            form_description: selectedForm.form_description,
            language: selectedForm.language,
            pdf_url: selectedForm.pdf_url,
            uploader_username: null,
            form_id: selectedForm.form_id,
            finalized: null,
            reviewers: selectedForm.reviewers,
            approved_by_all_reviewers: false,
            file_urls: [],
            upload_date: null,
            last_modified_date: null,
            data: mappedDatas,
          },
          token
        );
      }

      if (files.length) {
        const uploadPromises = files.map((file) =>
          uploadFileApi({ file, form_id: response.form_id }, token)
        );
        await Promise.all(uploadPromises);
      }

      localStorage.setItem("submittedForm", JSON.stringify([formData]));
      navigate("/myforms");
    } catch (error) {
      if (error.detail === 'Invalid token.') {
        setError("Session expired. Please log in again.");
        navigate("/login");
      } else {
        setError(error.message || "An error occurred");
      }
    }
  };


  const getDefaultValues = () => {
    if (selectedForm) {
      selectedForm.data.forEach((data) => {
        data.fields.forEach((field) => {
          if (field.value.length) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              [data.section_name]: {
                ...prevFormData[data.section_name],
                [field.field_name]: field.value[0],
              },
            }));
          }
        });
      });
    }
  };

  const handleFileChange = (e, fieldId, section_name) => {
    const file = e.target.files[0];
    if (file) {
      setFiles([...files, file]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [section_name]: {
          ...prevFormData[section_name],
          [fieldId]: file.name,
        },
      }));
      const reader = new FileReader();
      reader.onload = (e) => setFilePreview(e.target.result);
      reader.readAsDataURL(file);
      setSelectedFileFieldId(fieldId);
    }
  };

  const handleFieldChange = (fieldId, value, section_name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section_name]: {
        ...prevFormData[section_name],
        [fieldId]: value,
      },
    }));
  };

  const getChoices = async (endpoint) => {
    const response = await getChoicesApi(endpoint, token);
    return response;
  };

  const renderChoices = (field) => {
    let choices = field.choices || [];
    // Uncomment if using an API for choices
    // if (field.api_for_choices) choices = getChoices(field.api_for_choices.url);
    return choices.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ));
  };


useEffect(() => {
  if (selectedForm) {
    selectedForm.data.forEach((section) => {
      if (section.repeatable) {
        setRepeatableSections((prevRepeatableSections) => ({
         ...prevRepeatableSections,
          [section.section_name]: 1,
        }));
      }
    });
  }
}, [selectedForm]);

const handleAddSection = (sectionName) => {
  setRepeatableSections((prevRepeatableSections) => ({
    ...prevRepeatableSections,
    [sectionName]: prevRepeatableSections[sectionName] + 1,
  }));

  const newSectionData = { ...formData };
  const sectionIndex = selectedForm.data.findIndex((section) => section.section_name === sectionName);
  const sectionFields = selectedForm.data[sectionIndex].fields;

  if (!Array.isArray(newSectionData[sectionName])) {
    newSectionData[sectionName] = [{}];
  } else {
    newSectionData[sectionName] = [...newSectionData[sectionName], {}];
  }

  sectionFields.forEach((field) => {
    newSectionData[sectionName][newSectionData[sectionName].length - 1][field.field_name] = '';
  });

  setFormData(newSectionData);
};

const handleRemoveSection = (sectionName) => {
  setRepeatableSections((prevRepeatableSections) => ({
    ...prevRepeatableSections,
    [sectionName]: prevRepeatableSections[sectionName] - 1,
  }));

  const newSectionData = { ...formData };
  newSectionData[sectionName].pop();

  setFormData(newSectionData);
};


const renderFormField = (field, section_name) => {
  const isMandatory = field.mandatory;

  const getLabelWithAsterisk = (label) => (
    <span>
      {isMandatory && <span style={{ color: 'red' }}> *</span>} {label}
    </span>
  );

  switch (field.field_type) {
    case "NUMERIC_POSITIVE_INTEGER":
      return (
        <Grid item xs={12} sm={6} key={field.field_name}>
          <FormLabel sx={{ fontSize: '1.1rem' }}>{getLabelWithAsterisk(field.field_name)}</FormLabel>
          <TextField
            key={field.field_name}
            type="number"
            InputProps={{
              inputProps: { min: 0, step: 1 },
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={
              formData[section_name]
                ? formData[section_name][field.field_name]
                : ""
            }
            onChange={(e) =>
              handleFieldChange(field.field_name, e.target.value, section_name)
            }
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'gray',
                },
                '&:hover fieldset': {
                  borderColor: '#6B8A7A',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6B8A7A',
                },
              },
            }}
          />
        </Grid>
      );

    case "DATE":
      return (
        <Grid item xs={12} sm={6} key={field.field_name}>
          <FormControl key={field.field_name} fullWidth margin="normal">
            <FormLabel sx={{ fontSize: '1.1rem' }}>{getLabelWithAsterisk(field.field_name)}</FormLabel>
            <TextField
              key={field.field_name}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              margin="normal"
              defaultValue={
                formData[section_name]
                  ? formData[section_name][field.field_name]
                  : ""
              }
              onChange={(e) =>
                handleFieldChange(field.field_name, e.target.value, section_name)
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: '#6B8A7A',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6B8A7A',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'gray', // Default label color
                  '&.Mui-focused': {
                    color: '#6B8A7A', // Label color when focused
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
      );

      case "YES_OR_NO":
        return (
          <Grid item xs={12} sm={6} key={field.field_name}>
            <FormControl component="fieldset" key={field.field_name}>
              <FormLabel component="legend" sx={{ fontSize: '1.1rem' }}>
                {getLabelWithAsterisk(field.field_name)}
              </FormLabel>
              <RadioGroup
                row
                aria-label={field.field_name}
                name={field.field_name}
                defaultValue={field.value[0]}
                onChange={(e) => handleFieldChange(field.field_name, e.target.value, section_name)}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6B8A7A',
                        },
                      }}
                    />
                  }
                  label={t('Yes')}
                  sx={{ marginRight: '1rem' }}
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#6B8A7A',
                        },
                      }}
                    />
                  }
                  label={t('No')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        );

      case "SINGLE_SELECT":
        return (
          <Grid item xs={12} sm={6} key={field.field_name} 
           sx={{ marginTop: {
            // xs: '16px',  // Margin for extra-small screens
            // sm: '24px',  // Margin for small screens and up
            md: '42px' ,  // Margin for medium screens and up
          },}}>
            <FormControl
              key={field.field_name}
              variant="outlined"
              fullWidth
              margin="normal"
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: '#6B8A7A',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6B8A7A',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'gray',
                  '&.Mui-focused': {
                    color: '#6B8A7A',
                  },
                 // Ensure consistent label positioning
            top: isFarsi ? '8px' : '0x', // Adjust these values as needed
            left: isFarsi ? '14px' : '14px',
                },
              }}
            >
               <InputLabel
          shrink
          sx={{ fontSize: '1.1rem' }}
        >
          {getLabelWithAsterisk(field.field_name)}
        </InputLabel>
              
              {/* <FormLabel sx={{ fontSize: '1.1rem' }}>{getLabelWithAsterisk(field.field_name)}</FormLabel> */}
              <Select
              label={getLabelWithAsterisk(field.field_name)}
              value={
              formData && formData[section_name] && formData[section_name][field.field_name] !== undefined
               ? formData[section_name][field.field_name]
              : "Select"  // مقدار پیش‌فرض به "Select" تغییر یافت
               }
               onChange={(e) =>
               handleFieldChange(
               field.field_name,
                e.target.value,
              section_name
    )
  }
>
  {/* اضافه کردن گزینه پیش‌فرض */}
  <MenuItem value="Select" disabled>
    Select
  </MenuItem>
  
  {renderChoices(field)}
            </Select>

            </FormControl>
          </Grid>
        );

    case "LARGE_TEXT_BOX":
    case "SMALL_TEXT_BOX":
    case "NUMERIC_FLOAT":
      return (
        <Grid item xs={12} sm={6} key={field.field_name}  >
          <FormControl key={field.field_name} fullWidth margin="normal">
            <FormLabel sx={{ fontSize: '1.1rem' }}>{getLabelWithAsterisk(field.field_name)}</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              multiline={field.field_type === "LARGE_TEXT_BOX"}
              rows={field.field_type === "LARGE_TEXT_BOX" ? 4 : 1}
              type={field.field_type === "NUMERIC_FLOAT" ? "number" : "text"}
              defaultValue={
                formData[section_name]
                  ? formData[section_name][field.field_name]
                  : ""
              }
              onChange={(e) =>
                handleFieldChange(field.field_name, e.target.value, section_name)
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: '#6B8A7A',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6B8A7A',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'gray',
                  '&.Mui-focused': {
                    color: '#6B8A7A',
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
      );

    case "CHECKBOX":
      return (
        <Grid item xs={12} sm={6} key={field.field_name}>
          <FormControlLabel
            key={field.field_name}
            control={<Checkbox />}
            label={getLabelWithAsterisk(field.field_name)}
            defaultValue={
              formData[section_name]
                ? formData[section_name][field.field_name]
                : ""
            }
            onChange={(e) =>
              handleFieldChange(
                field.field_name,
                e.target.checked,
                section_name
              )
            }
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'gray',
                },
                '&:hover fieldset': {
                  borderColor: '#6B8A7A',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6B8A7A',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'gray',
                '&.Mui-focused': {
                  color: '#6B8A7A',
                },
              },
            }}
          />
        </Grid>
      );

    case "IMAGE":
    case "DOCUMENT":
      return (
        <Grid item xs={12} sm={6} key={field.field_name}>
          <div>
            <FormLabel component="legend">{getLabelWithAsterisk(field.field_name)}</FormLabel>
            <input
              key={field.field_name}
              type="file"
              accept={
                field.field_type === "IMAGE"
                  ? "image/*"
                  : ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
              }
              onChange={(e) =>
                handleFileChange(e, field.field_name, section_name)
              }
            />
            {selectedFileFieldId === field.field_name && filePreview && (
              <img
                src={filePreview}
                alt="Preview"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
            )}
          </div>
        </Grid>
      );

    default:
      return null;
  }
};


if (redirectToMyForms) {
  return <MyForms submittedFormData={submittedFormData} />;
}
const isFarsi = i18n.language === 'fa';
const direction = i18n.language === 'fa' ? 'rtl' : 'ltr';

return (
  <div style={{direction:direction, overflowX: 'hidden'}}>
  <Box m="2rem 3rem" sx={{ direction: i18n.language === "fa"? "rtl" : "ltr" }}>
    <Header
      title={` ${selectedForm? selectedForm.title || "" : ""}`}
    />

    {selectedForm? (
      <form>
        {selectedForm.data.map((section, sectionIndex) => (
  <Card key={section.section_name} sx={{ padding: 2, mb: 2, border: "1px solid #CECECE", borderRadius: 5, marginTop: "2rem",backgroundColor:"#FAFAFA" }}>
    <Typography variant="h2" component="h3" sx={{ color: "#6B8A7A" }}>
      {section.section_name}
    </Typography>
    {section.repeatable && (
      <div>
        <IconButton
          aria-label="add"
          onClick={() => handleAddSection(section.section_name)}
          sx={{
            justifyContent: "left",
            mt: 1,
            mb: 1,
            fontSize: 24,
            padding: 2,
            borderRadius: 8,
            border: "2px solid #FFB827",
            "&:hover": {
              backgroundColor: "#f7f7f7",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            },
            mx: 1,
          }}
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {repeatableSections[section.section_name] > 1 && (
          <IconButton
            aria-label="remove"
            onClick={() => handleRemoveSection(section.section_name)}
            sx={{
              mt: 1,
              mb: 1,
              border: "2px solid #E20000",
              padding: 2,
              gap: 2,
              borderRadius: 8,
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#f7f7f7",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
              },
              mx: 1,
            }}
          >
            <RemoveCircleIcon fontSize="inherit" />
          </IconButton>
        )}
      </div>
    )}
    {Array(repeatableSections[section.section_name]).fill(0).map((_, index) => (
     <div key={index} style={{
      border: "1px solid #ddd",
      padding: "1rem",
      borderRadius: "1rem",
      marginTop: "1.5rem",
      backgroundColor: "#fff",
      boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.1)"
    }}>
        <Typography variant="h4" component="h4" sx={{ color: "#333" }}>
          {section.section_name}
        </Typography>
        <Grid container spacing={2}>
          {section.fields.map((field) =>
            renderFormField(field, section.section_name, sectionIndex)
          )}
        </Grid>
      </div>
    ))}
  </Card>
))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2, mb: 5, width: "2rem", fontSize: "1.2rem", paddingRight: "3rem", paddingLeft: "3rem" }}
        >
          {t("Submit")}
        </Button>
      </form>
    ) : (
      <Typography>{t("loading")}</Typography>
    )}
    <Footer/>
  </Box>
  </div>
);
};
export default Formsofx2;